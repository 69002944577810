import { BASE_URL, PROJECT_REF, QUALIFICATION_URL } from '../constant'
import { post } from '../service/API'
import { navigate } from 'gatsby'
import { sendToDataLayer } from '../utils/DataLayer'
import { getUtm } from '../utils/utm'

type SignUpReponse = {
  result: true
  data: {
    userName: string
    email: string
    authChallenge: string
    projectId: string
    type?: string
  }
}

/*
const validEmail = regex.test(email)
const regex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
*/

type SignUpFormData = {
  email: string
  acceptedTerms: boolean
  zipcode: string // the actual input in the inpiut field
  city?: string
  locationId: string // something like "postcode.106581740"
  position: {
    ip: string
    country_code: string
    latitude: number
    longitude: number
  }
}

type UtmType = {
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_study?: string
}

type DataWithGrant = SignUpFormData & {
  project: string
  grantType: string
  language: string // es or en
  utm: UtmType
}

export const signUpUser = (data: SignUpFormData, language) => {
  console.log(' SignUpUser > data = ', data)

  //sendToDataLayer({ event: 'FORM_INIT' })
  sendToDataLayer({ event: 'FORM_INIT', projectRef: PROJECT_REF, language: language })

  const utmObj: UtmType = getUtm()
  const p = BASE_URL + '/users/signup'

  //
  // PROJECT_REF is set in env and is how the landing page is matched with the project
  //
  const dataWithGrant: DataWithGrant = {
    grantType: 'password',
    project: PROJECT_REF,
    language: language,
    utm: utmObj,
    ...data,
  }

  return post(p, dataWithGrant)
    .then((result: SignUpReponse) => {
      //
      // send event to tag manager
      //

      sendToDataLayer({ event: 'FORM_COMPLETED', projectRef: PROJECT_REF, language: language })

      //
      // User already exist - they should have recieved an email
      //
      if (result.data && result.data.type === 'USER EXIST') {
        navigate('/emailLogin', { state: { userEmail: result.data.email || 'missing email' } })
        return
      }

      const authChallenge = result.data.authChallenge || null
      const email = encodeURIComponent(result.data.email || null)
      const projectId = result.data.projectId || null

      if (authChallenge && email) {
        const p = `${QUALIFICATION_URL}/${language}/?authChallenge=${authChallenge}&email=${email}&projectId=${projectId}`
        window.location.replace(p)
      }
    })
    .catch((error) => {
      console.log('error = ', error)
      alert(JSON.stringify(error, null, 2))
      return error
    })
}
