/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/06/2022.
 */

import React, { useEffect } from 'react'
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox'
import { getReq } from '../../service/API'
import { DEBUG, GATSBY_MAPBOX_API_KEY } from '../../constant'
import './combo-box.scss'
import '../signUpForm/sign-up-form.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const BORDER_COLOR = '#b6b6b6'

const formKey = 'zipcode'
const locationKey = 'locationId'
const cityKey = 'city'
export const ComboBox = ({ formHook }) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formHook

  //
  // simple hashmap for storing data
  //
  const optionData = React.useRef({})
  const errorState = errors[formKey]

  const [searchTerm, setSearchTerm] = React.useState('')
  const mapBoxPlaces = useCitySearch(searchTerm)

  //
  // trigger on input change
  //
  const handleSearchTermChange = (event) => {
    const value = event.target.value

    console.log(' ComboBox > handleSearchTermChange = ', value)
    setSearchTerm(value)
    setValue(formKey, value)

    //
    // set the location id from mapbox
    //
    const data = optionData.current[value] || { id: null }
    setValue(locationKey, data.id)

    const placeNames = data?.place_name?.split(', ')
    if (placeNames?.length > 0) {
      setValue(cityKey, placeNames[1])
    }
  }

  const codes = mapBoxPlaces
  console.log(' ComboBox > codes = ', codes)

  const codeSelect = (value) => {
    const data = optionData.current[value]

    console.log('den zip data der er valgt:')
    console.log(data)

    console.log(' ComboBox > data = ', data)
    setSearchTerm(value)
    setValue(formKey, value)
    setValue(locationKey, data.id)

    const placeNames = data?.place_name?.split(', ')
    if (placeNames?.length > 0) {
      setValue(cityKey, placeNames[1])
    }
  }

  const validateZipCode = (value) => {
    const locationId = getValues(locationKey)
    return locationId === null ? t('validLocationId') : true
  }

  //
  // We handle saving the zipcode and the loaction from Mapbox manually
  //
  useEffect(() => {
    register(formKey, {
      required: t('zipcodeInvalid'),

      minLength: {
        value: 4,
        message: t('zipcodeMin'),
      },
      maxLength: {
        value: 4,
        message: t('zipcodeMax'),
      },
      validate: validateZipCode,
      //validate: { validateZipCode: validateZipCode, message: 'Please select from list' },
    })
    register(locationKey)
    register(cityKey)
  }, [])

  const borderState = errorState ? 'red' : BORDER_COLOR
  const zipPlaceholder = t('form.fieldZipCode')

  return (
    <div className="col-12">
      <p className="p-small text-end">{t('form.fieldZipCodeInfo')}</p>
      <div
        className="d-flex signup-form align-items-center "
        style={{ border: `1px solid ${borderState}`, borderRadius: 4 }}
      >
        <div
          className="pe-2 ps-3"
          style={{
            maxWidth: 60,
          }}
        >
          {<img className="w-100 " src={'../../flags/dk.png'} alt="" />}
        </div>

        <div className="w-100 h-100" style={{}}>
          <Combobox aria-label="Zipcode" onSelect={codeSelect}>
            <div className="form-floating ">
              <ComboboxInput
                autocomplete={false}
                autoComplete="off"
                id="floatingInput"
                className="city-search-input w-100 form-control"
                placeholder={zipPlaceholder}
                style={{ border: 'none', outline: 'none !important', boxShadow: '0 0 0px #719ECE', fontSize: '1rem' }}
                onChange={handleSearchTermChange}
                value={searchTerm}
              />

              <label htmlFor="floatingInput" style={{ color: '#8a8a8a', fontSize: '1rem' }}>
                {zipPlaceholder}
              </label>
            </div>

            {/*

            If a selection is made from the list, we dont want to render the autocomplete again
            So if the serachTerm is matching the loaded result from mapbox - we simply select that one

            */}

            {/*{codes && codes.length === 1 && codes[0].text === searchTerm ? (
              <span style={{ display: 'block' }}></span>
            ) : null}*/}

            {codes && (
              <ComboboxPopover className="shadow-popup">
                {codes.length > 0 ? (
                  <ComboboxList>
                    {codes.map((code) => {
                      const id = code.id
                      const text = code.text
                      const place = code.place_name.split(',')[0] + code.place_name.split(',')[1]
                      const fullStr = code.place_name //, ${city.state}`
                      const postFix = place.replace(text, '')

                      // save entry
                      optionData.current[text] = code

                      return (
                        <ComboboxOption key={id} value={text}>
                          <div className="">
                            {text}, {postFix}
                          </div>
                        </ComboboxOption>
                      )
                    })}
                  </ComboboxList>
                ) : codes.length === 0 || (codes.length === 1 && codes[0].text !== searchTerm) ? (
                  <span style={{ display: 'block', margin: 8 }}>No results found</span>
                ) : null}
              </ComboboxPopover>
            )}
          </Combobox>
        </div>
      </div>
      {errorState ? <div className="p-small text-danger mt-1">{errorState.message}</div> : null}
    </div>
  )
}

const min = 2
const max = 8

function useCitySearch(searchTerm) {
  const [cities, setCities] = React.useState([])

  React.useEffect(() => {
    if (DEBUG && searchTerm.length < max) {
      //console.log(' ComboBox > NO lookups with more then 6 letters  ')
    }

    if (cache[searchTerm]) {
      console.log(' ComboBox > fromCache = ')
    }

    if (searchTerm.trim() !== '' && searchTerm.length > min && searchTerm.length < max) {
      let isFresh = true
      fetchCities(searchTerm).then((cities) => {
        if (isFresh) setCities(cities)
      })
      return () => (isFresh = false)
    }
  }, [searchTerm])

  return cities
}

//'https://api.mapbox.com/geocoding/v5/mapbox.places/3312.json?country=us&types=postcode&access_token=pk.eyJ1Ijoic3R1ZGllc2FuZG1lIiwiYSI6ImNsNDN3d2oxNTE1dzczY282MXF1YmEwb3cifQ._-cYTw7nUlF5vAs3ovZmlw&autocomplete=true&limit=5'

const API_TOKEN = GATSBY_MAPBOX_API_KEY
const cache = {}

function fetchCities(value) {
  //
  // if a selction made from the list on one result is returned from the cache
  //
  if (cache[value]) {
    console.log(' ComboBox > cache[value].length = ', cache[value].length)
    if (cache[value].length !== undefined) {
      return Promise.resolve(cache[value])
    } else {
      console.log(' ComboBox > wrap = ')
      return Promise.resolve([cache[value]])
    }
  }
  const country = 'dk'
  const p = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?country=${country}&types=postcode&access_token=${API_TOKEN}&autocomplete=true&limit=5`

  //
  // we try to both catch both the list of results but also the incomplete query
  //
  //
  return getReq(p).then((result) => {
    const resultArr = result.features || []

    // cache the full arr based on "3324"
    cache[value] = result.features

    //
    // add each full result to the cache based on the full query
    // this means that "3324" would cache a list of "3324*"
    //
    resultArr.forEach((item) => {
      cache[item.text] = item
    })
    return resultArr
  })
}
